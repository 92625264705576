import './nav'
import { elementExist, _breakpoints } from './utils'

elementExist('.home-slider', (query) => {
  const homeSlider = new Splide ( query, {
    type: 'loop',
    autoplay: true,
     interval: 3500
  } )
  homeSlider.mount()
})

elementExist('.news-slider', (query) => {
  const newsSlider = new Splide ( query, {
    arrows: false,
    pagination: false,
    perPage: 3,
    breakpoints: {
      [ _breakpoints.lg[0] ]: { perPage: 2 },
      [ _breakpoints.sm[0] ]: { perPage: 1 },
    }
  } )
  newsSlider.mount()
})

elementExist('.brands-slider', (query) => {
  const newsSlider = new Splide ( query, {
    arrows: false,
    pagination: false,
    perPage: 4,
    breakpoints: {
      [ _breakpoints.lg[0] ]: { perPage: 3 },
      [ _breakpoints.md[0] ]: { perPage: 2 },
      [ _breakpoints.sm[0] ]: { perPage: 1 },
    }
  } )
  newsSlider.mount()
})

elementExist('.combo', (query, combos) => {
  combos.forEach(combo => {
    const comboitems = combo.querySelectorAll('.comboitem > .title')
    comboitems.forEach(item => {
      item.onclick = function() {
        const activeItem = combo.querySelector('.comboitem.active')
        const currentItem = this.parentNode
        if(activeItem === currentItem) {
          currentItem.classList.remove('active')
        } else {
          if(activeItem) activeItem.classList.remove('active')
          if(currentItem) currentItem.classList.add('active')
        }
      }
    })
  });
})

function wrap(el, wrapper) {
  el.parentNode.insertBefore(wrapper, el);
  wrapper.appendChild(el);
}

elementExist('#the_content', (query) => {
  const imgs = document.querySelectorAll('#the_content img')
  if(imgs.length) {
    imgs.forEach(element => {
      const link = document.createElement('a')
      link.href = element.src
      link.setAttribute('data-fancybox', 'the_content')
      wrap(element, link)
    });
  }
})

Fancybox.bind()
AOS.init()